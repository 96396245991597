<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑' : '新建'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 选择客服 -->
      <a-form-item
        label="选择客服"
        name="open_kfid"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.open_kfid"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :disabled="!!id"
        >
          <a-select-option
            v-for="item in followList"
            :key="`${item.open_kfid}-${item.name}`"
            :value="item.open_kfid"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 后台备注名 -->
      <a-form-item
        label="后台备注名"
        name="message_name"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.message_name"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 客服欢迎语 -->
      <a-form-item
        label="客服欢迎语"
        name="message_welcome"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-textarea
          placeholder="请输入"
          v-model:value="formState.message_welcome"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          :maxlength="100"
        />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { messageFollowList, messageKfSave } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 企业微信客服列表
let followList = ref([])
// 表单
let formState = reactive({
  // 客服名称
  kf_name: undefined,
  // 客服id
  open_kfid: undefined,
  // 后台备注名
  message_name: undefined,
  // 客服欢迎语
  message_welcome: undefined
})

// 打开抽屉
function showDrawer (record) {
  // 展开
  visible.value = true
  // 记录 ID
  if (record) { id.value = record.id }
  // 获取客服列表
  getMessageFollowList()
  // 赋值
  nextTick(() => {
    // 有 ID 需要编辑
    if (id.value) {
      // 取出参数值
      for (let i in formState) {
        const v = record[i]
        formState[i] = v
      }
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    visible.value = false
  }
}

// 客服列表
function getMessageFollowList () {
  messageFollowList().then(res => {
    const { code, data } = res
    if (code === 0) {
      followList.value = data
    }
  })
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    // 客服
    const kf = followList.value.find(item => formState.open_kfid === item.open_kfid)
    // 组装上传参数
    const params = {
      // 用户id
      id: id.value,
      // 表单数据
      ...formState,
      // 客服名称
      kf_name: kf.name
    }
    messageKfSave(params).then(res => {
      isLoading.value = false
      const { code, msg } = res
      if (code === 0) {
        message.success('提交成功')
        // 更新父组件
        emit('success')
        onClose()
      } else {
        message.error(res.message || msg)
      }
    }).catch(() => {
      message.error('提交失败')
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-hide-label /deep/ .ant-form-item-label {
  opacity: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
</style>