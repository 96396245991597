<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>企业客服列表</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <div style="flex: 1;"></div>
    <a-button type="primary" @click="touchAdd()"><plus-outlined /> 新建</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchAdd(record)">编辑</a>
      </template>
    </template>
  </a-table>
  <!-- 新增 -->
  <Add ref="RefAdd" @success="getMessageKfList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Pub from '@/utils/public'
import { message } from 'ant-design-vue'
import { messageKfList } from '@/api/operate'
import Add from './components-service-list/Add.vue'

// 新增、编辑组件实例
let RefAdd = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '前台客服名称',
    dataIndex: 'kf_name',
    key: 'kf_name'
  },
  {
    title: '后台备注名称',
    dataIndex: 'message_name',
    key: 'message_name'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取企业客服列表
  getMessageKfList()
})

// 获取企业客服列表
function getMessageKfList () {
  isLoading.value = true
  const params = {
    page: pagination.current,
    page_size: pagination.pageSize
  }
  messageKfList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getMessageKfList()
}

// 编辑
function touchAdd (record) {
  RefAdd.value.showDrawer(record || {})
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>